"use client";

import { useEffect } from "react";

import { dispatchRouteChangeEvent } from "~/utils/route-events";

export default function Template({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    dispatchRouteChangeEvent("completed");
  }, []);
  return <div>{children}</div>;
}
